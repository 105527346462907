import React, { useEffect, useState, useReducer } from "react";
import { activateUser, addUser, deActivateUser, deleteUser, editUser, getCourses, getModules, getRoles, getUsers, getUsersByCourse } from "../../services/apiService";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Form,
  Modal,
  Pagination
} from "react-bootstrap";
import Sidebar from "../Sidebar/Sidebar";
import moment from 'moment';
import { useAuth } from "../../contexts/AuthContext";
import { USER_ROLES, isAdmin, isSoru, decodeToken, getUserRole, getUserModule } from "../../helper/constants";
import { useTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';

export default function Users() {
  const { t } = useTranslation(['headers']);
  const [, forceUpdate] = useReducer(x => x + 1, 0);

  const { user } = useAuth();
  const [modalVisible, setModalVisible] = useState(false)
  const [editModalVisible, setEditModalVisible] = useState(false)
  const [users, setUsers] = useState([])
  const [roles, setRoles] = useState([])
  const [courses, setCourses] = useState([])
  const [form, setForm] = useState({
    firstName: '',
    lastName: '',
    userName: '',
    password: '',
    roleId: null,
    courseId: null
  })
  const [editForm, setEditForm] = useState({
    firstName: '',
    lastName: '',
    userName: '',
    password: '',
    roleId: null,
    courseId: null
  })
  const [editId, setEditID] = useState(null)
  const [modules, setModules] = useState([])
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [searchForm, setSearchForm] = useState({
    firstName: '',
    lastName: '',
    userName: '',
  })
  const [role, setRole] = useState(null)

  useEffect(() => {
    const decoded = decodeToken()
    const decodedRole = decoded ? decoded.role : null;
    setRole(decoded ? decoded.role : null)
    document.title = t('headers:panel');
    if(decodedRole !== USER_ROLES.ADMIN) {
      setForm((prevForm) => ({
        ...prevForm,
        roleId: USER_ROLES.USER,
        courseId: user.course.id
      }));
    } else {
      setForm((prevForm) => ({
        ...prevForm,
        courseId: user.course.id
      }));
    }
    fetchModules()
  }, [])

  useEffect(() => {
    fetchUsers(currentPage)
  }, [currentPage, role])

  const fetchUsers = async (page) => {
    const response = isAdmin(role) ? await getUsers(page, searchForm) : await getUsersByCourse(user.course.id, page, searchForm);
    setUsers(response.users);
    setTotalPages(Math.ceil(response.totalCount / 10));
  }

  const fetchRoles = async () => {
    const role = await getRoles();
    if (isSoru(role)) {
      const filteredRoles = role.filter(r => [2, 3, 4].includes(r.id));
      setRoles(filteredRoles);
    } else {
      setRoles(role);
    }
  }

  const fetchCourses = async () => {
    const course = await getCourses();
    setCourses(course)
  }

  const fetchModules = async () => {
    const modules = await getModules();
    setModules(modules)
  }

  const handleAddUser = async () => {
    const response = await addUser(form)
    if(response.isError) {
      toast.error(t(`error:${response.errorCode}`))
    }
    else fetchUsers(1)
    setModalVisible(false)
  }

  const handleEditUser = async () => {
    const response = await editUser(editForm, editId)
    if(response) fetchUsers(currentPage)
    setEditModalVisible(false)
  }

  const handleDelete = async (id) => {
    const response = await deleteUser(id)
    if(response) fetchUsers(currentPage)
  }

  const handleIsActive = async (item) => {
    if(item.isActive) {
      const response = await deActivateUser(item.id, false)
      if(response) fetchUsers(currentPage)
    } else {
      const response = await activateUser(item.id, true)
      if(response) fetchUsers(currentPage)
    }
  }

  const handleSearchUser = async () => {
    const response = isAdmin(role) ? await getUsers(1, searchForm) : await getUsersByCourse(user.course.id, 1, searchForm) 
    setUsers(response.users)
    setTotalPages(Math.ceil(response.totalCount / 10))
  }

  const handleResetFilter = async () => {
    setSearchForm({
      firstName: '',
      lastName: '',
      userName: ''
    })
    forceUpdate()
    fetchUsers(1)
  }

  // const handleEdit = (item) => {
  //   setEditForm({
  //     name: item.name,
  //     link: item.link
  //   })
  //   setEditID(item.id)
  //   setEditModalVisible(true)
  // }

  const handleAddUserVisible = () => {
    setModalVisible(true)
    if(roles.length === 0 && (isAdmin(role) || isSoru(role))) fetchRoles()
    if(courses.length === 0 && (isAdmin(role) || isSoru(role))) fetchCourses()
  }

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <div style={{ flex: 1, height: "100vh", width: "100%" }}>
      <Row style={{ flex: 1, height: "100vh" }}>
        <Col sm={2} style={{ height: "100vh" }}>
          <Sidebar />
        </Col>
        <Col sm={10}>
          <Container>
            <Form className="mt-4">
              <Row>
                <Col>
                  <Form.Group controlId="formFirstName">
                    <Form.Control
                      type="text"
                      placeholder="Adı"
                      value={searchForm.firstName}
                      onChange={(e) => setSearchForm({
                        ...searchForm,
                        firstName: e.target.value
                      })}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="formLastName">
                    <Form.Control
                      type="text"
                      placeholder="Soyadı"
                      value={searchForm.lastName}
                      onChange={(e) => setSearchForm({
                        ...searchForm,
                        lastName: e.target.value
                      })}
                    />
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="searchFormFirstName">
                    <Form.Control
                      type="text"
                      placeholder="Kullanıcı Adı"
                      value={searchForm.userName}
                      onChange={(e) => setSearchForm({
                        ...searchForm,
                        userName: e.target.value
                      })}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col className="text-end">
                  <Button variant="danger" className="mt-3 mx-3" onClick={handleResetFilter}>
                    Filtreleri Kaldır
                  </Button>
                  <Button variant="primary" className="mt-3" onClick={handleSearchUser}>
                    Ara
                  </Button>
                </Col>
              </Row>
            </Form>
            <Container className="mt-3 p-0">
              <Row>
                <Col>
                  <div>
                    <Button
                      variant="primary"
                      onClick={handleAddUserVisible}
                    >
                      Kullanıcı Ekle
                    </Button>
                    <Table className="mt-3" striped bordered hover>
                      <thead>
                        <tr>
                          <th>ID</th>
                          <th>Ad</th>
                          <th>Soyad</th>
                          <th>Kullanıcı Adı</th>
                          {(isAdmin(role) || isSoru(role)) && <th>Rol</th>}
                          <th>Modül</th>
                          <th>Aktiflik</th>
                          <th>Oluşturulma Tarihi</th>
                          {(isAdmin(role) || isSoru(role)) && <th>İşlemler</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {users && users.map((item) => (
                          <tr key={item.id}>
                            <td>{item.id}</td>
                            <td>{item.firstName}</td>
                            <td>{item.lastName}</td>
                            <td>{item.userName}</td>
                            {(isAdmin(role) || isSoru(role)) && <td>{getUserRole(item.roleId)}</td>}
                            <td>{getUserModule(item.moduleId)}</td>
                            <td>{item.isActive ? "Aktif" : "Pasif"}</td>
                            <td>{moment(item.createdAt).format('DD.MM.YYYY HH:mm')}</td>
                            <td>
                              {/* <Button variant="info" size="sm" onClick={() => handleEdit(item)}>
                                Düzenle
                              </Button>{" "} */}
                              {(isAdmin(role) || isSoru(role)) && 
                                <Button variant="danger" size="sm" onClick={() => handleDelete(item.id)}>
                                Sil
                                </Button>
                              }
                              {item.isActive ? 
                                <Button style={{ marginLeft: 10 }} variant="warning" size="sm" onClick={() => handleIsActive(item)}>
                                  Pasifleştir
                                </Button>
                                :
                                <Button style={{ marginLeft: 10 }} variant="success" size="sm" onClick={() => handleIsActive(item)}>
                                  Aktifleştir
                                </Button>
                              }
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                    <Pagination>
                      <Pagination.Prev
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1}
                      />
                      {Array.from({ length: totalPages }, (_, i) => (
                        <Pagination.Item
                          key={i + 1}
                          active={i + 1 === currentPage}
                          onClick={() => handlePageChange(i + 1)}
                        >
                          {i + 1}
                        </Pagination.Item>
                      ))}
                      <Pagination.Next
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      />
                    </Pagination>
                  </div>
                </Col>
              </Row>
            </Container>

            <Modal show={modalVisible} onHide={() => setModalVisible(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Kullanıcı Ekle</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group className="mb-2" controlId="formUserName">
                    <Form.Label>Kullanıcı Adı</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Kullanıcı Adı"
                      onChange={(e) => setForm({
                        ...form,
                        userName: e.target.value
                      })}
                    />
                  </Form.Group>
                  {form.userName !== "" && form.userName.length < 6 && (
                    <p className="text-center text-danger fw-bold mt-3">Kullanıcı adı en az 6 karakterden oluşmalıdır.</p>
                  )}
                  <Form.Group className="mb-2" controlId="createFormFirstName">
                    <Form.Label>Adı</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Adı"
                      onChange={(e) => setForm({
                        ...form,
                        firstName: e.target.value
                      })}
                    />
                  </Form.Group>
                  <Form.Group className="mb-2" controlId="createFormLastName">
                    <Form.Label>Soyadı</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Soyadı"
                      onChange={(e) => setForm({
                        ...form,
                        lastName: e.target.value
                      })}
                    />
                  </Form.Group>
                  <Form.Group className="mb-2" controlId="formPassword">
                    <Form.Label>Şifre</Form.Label>
                    <Form.Control
                      type="password"
                      placeholder="Şifre"
                      onChange={(e) => setForm({
                        ...form,
                        password: e.target.value
                      })}
                    />
                  </Form.Group>
                  {form.password !== "" && form.password.length < 6 && (
                    <p className="text-center text-danger fw-bold mt-3">Şifre en az 6 karakterden oluşmalıdır.</p>
                  )}
                  {(isAdmin(role) || isSoru(role)) && <Form.Group className="mb-2">
                    <Form.Label>Rol</Form.Label>
                    <Form.Select
                      name="role"
                      value={form.roleId}
                      onChange={(e) => {
                        setForm((prevForm) => ({
                          ...prevForm,
                          roleId: e.target.value
                        }));
                      }}
                      required
                    >
                      <option key={0} value="" selected disabled>Seçiniz</option>
                      {
                        roles.map(role => (
                          <option key={role.id} value={role.id}>{role.name}</option>
                        ))
                      }
                    </Form.Select>
                  </Form.Group>}
                  {isAdmin(role) && <Form.Group className="mb-2">
                    <Form.Label>Kurs</Form.Label>
                    <Form.Select
                      name="course"
                      value={form.courseId}
                      onChange={(e) => {
                        setForm((prevForm) => ({
                          ...prevForm,
                          courseId: e.target.value
                        }));
                      }}
                      required
                    >
                      <option key={0} value="" selected disabled>Seçiniz</option>
                      {
                        courses.map(course => (
                          <option key={course.id} value={course.id}>{course.name}</option>
                        ))
                      }
                    </Form.Select>
                  </Form.Group>}
                  <Form.Group className="mb-2">
                    <Form.Label>Eğitim Tipi</Form.Label>
                    <Form.Select
                      name="module"
                      value={form.moduleId}
                      onChange={(e) => {
                        setForm((prevForm) => ({
                          ...prevForm,
                          moduleId: e.target.value
                        }));
                      }}
                      required
                    >
                      <option key={0} value="" selected disabled>Seçiniz</option>
                      {role === USER_ROLES.COURSEADMIN ?
                        user?.modules.map(course => (
                          <option key={course.id} value={course.id}>{course.label}</option>
                        )) :
                        modules.map(course => (
                          <option key={course.id} value={course.id}>{course.label}</option>
                        ))
                      }
                    </Form.Select>
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setModalVisible(false)}
                >
                  Kapat
                </Button>
                <Button variant="primary" onClick={handleAddUser}>
                  Ekle
                </Button>
              </Modal.Footer>
            </Modal>

            <Modal show={editModalVisible} onHide={() => setEditModalVisible(false)}>
              <Modal.Header closeButton>
                <Modal.Title>Kullanıcı Ekle</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form>
                  <Form.Group controlId="formUserName">
                    <Form.Label>Kullanıcı Adı</Form.Label>
                    <Form.Control
                      value={editForm.name}
                      type="text"
                      placeholder="Kullanıcı Adı"
                      onChange={(e) => setEditForm({
                        ...editForm,
                        name: e.target.value
                      })}
                    />
                  </Form.Group>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  variant="secondary"
                  onClick={() => setEditModalVisible(false)}
                >
                  Kapat
                </Button>
                <Button variant="primary" onClick={handleEditUser}>
                  Düzenle
                </Button>
              </Modal.Footer>
            </Modal>
          </Container>
        </Col>
      </Row>
      <ToastContainer position="bottom-right" autoClose={3000} hideProgressBar={true} />
    </div>
  );
}
